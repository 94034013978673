:root {
  --orange: #F75201;
  --gap:  30px;
  --radius: 10px;
  /*  WHITELABEL PROPERTIES*/
  --primaryBackgroundColor: #ffff;
  --titleColor: #1a1d1f;
  --buttonTextColor: #ffff;
  --textColor: #13171a;
  --courseFontColor: #1a1d1f;
  --metadataTextColor: #1a1d1f;
  --metadataBoxColor: #ebebeb;
  --secondaryBackgroundColor: #fdecef;
  --supportImage: url('../images/shapes.svg');
  --paddingPage: 80px 60px 80px;
  --titleSize: 30px;
  --subtitleSize: 16px;
  --bannerSize: 220px;
  --descriptionSize: 16px;
  --fontFamily: 'Syne', sans-serif;
  --offerCardBackgroundColor: #ffff;
  --offerCardTextColor: #13171a;
}

a {
  text-decoration: none;
}

body {
	background-color: var(--secondaryBackgroundColor);
  font-family: var(--fontFamily);
}

.grid {
    display: grid;
    grid-gap: 30px;
    margin: 0 auto;
}

.card {
  background-color: #ffffff;
  border-radius: var(--radius);
  padding: 30px; /* mudança no padding aqui */
  position: relative;
}

.card .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: auto;
    color: #1a1d1f;
}

.action-card {
    text-align: center;
    background-color: #ebebeb;
    color: #8f8f8f;
    margin-top: 30px;
}

.action-card-description {
display: none;
}

.action-card:hover .action-card-description  {
display: block;
}

.action-card:hover {
  background-color: #dbdbdb;
  transition: 0.5s;
  transform: 0.2s translateY(-3px);
  box-shadow: 0 10px 20px  rgba(0, 0, 0, 0.2);
}


a .action-card p {
  font-size: 14px;
  color: #8f8f8f;
}

.page-top,
.badges-grid {
  grid-template-columns: repeat(4, calc((100% - 3 * var(--gap))/4));
}

.vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabpanel-menu {
  margin-top: 50px;
  margin-bottom: 25px;
}

.tabpanel {
  padding-bottom: 100px ;
}

.data-table-header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0 10px 0;
}

.align-right {
  margin-left: 95%;
}

.table-subHeader-button {
  background-color: var(--secondaryBackgroundColor) !important;
  color: black !important;
  width: 100%;
}

/* LAYOUT */

.drawer-logo {
  min-height: 100px !important;
}

.drawer-logo img {
  width: 100%;
}

main.grid {
  grid-template-columns: repeat(10, 10%);
  grid-gap: 0;
  min-width: calc(100% - 260px);
}

main .content {
  grid-column: 2 / span 8;
}

.back-wrapper {
  text-align: center;
  padding-top: 78px;
}

.back-wrapper button {
  background-color: #fff;
}

.container-menu-switcher {
  width: 100%;
  display: flex;
  margin: auto;
  margin-bottom: 0;
}

.switcher-menu-item {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.organization-name {
  color: #1a1d1f;
  padding: 5px;
  margin: 0px;
  display: flex;
}

.organization-button {
  display: flex;
  position: relative;
  color: #1a1d1f;
  padding: 0;
  margin: 0;
  left: 14px;
}

.organization-button-logout{
  display: flex;
  position: relative;
  color: #1a1d1f;
  padding: 0;
  margin: 0;
  right: 60px;
}

.menu-name {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
}

.menu-email {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  color: #8F8F8F;
}

.container-menu {
  display: flex;
  position: relative;
  flex-direction: column;

}

/* ELEMENTS */

.page-actions {
  text-align: right;
}

.settings-menu-button.MuiButton-contained {
  min-width: 46px;
  background-color: #b3b3b3 !important;
}

.settings-menu-button .MuiButton-endIcon {
  margin: 0;
}

.container-preview {
  margin-top: 20px;
  display: flex;
  position: relative;
  left: -15px;
}

/* DASHBOARD */

.page-top {
  margin-top: 80px;
}
.page-profile-pic {
  text-align: center;
  }
.page-profile-pic-wrapper {
  background-color: white;
  border-radius: 500px;
  padding: 35px;
  /* max-height: 100px; */
  text-align: center;
  border: 1px solid #d4d4d4;
  margin: 0 auto;
  display: inline-block;
}

.page-profile-pic img {
  height: 100px;
}

.page-info {
  grid-column: span 2;
}

.page-info .page-label {
  opacity: 0.6;
  font-size: 14px;
  color: #333333;
}
.page-info .page-description {
  font-size: 16px;
  color: #8f8f8f;
}
.page-info .page-name {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  margin: 10px 0;
}

.page-stat {
  grid-column: -2;
}

.page-stat .stat-card .stats-number {
  line-height: 100px;
}

.tokens-overview {
    text-align: left;
}

.tokens-overview .text-1 {
  font-size: 14px;
  color: #b2b2b2;
}
.tokens-overview .text-2 {
  font-size: 24px;
  color: #29292b;
    margin: 10px 0;
}
.tokens-overview .text-3 {
  font-size: 12px;
  color: #333333;
}

/* REWARD FORM */

.reward-creation-container{
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
  width: 100%;
}

#admin-rewards-add {
  width: 50%;
}

.new-reward-wrapper .reward-image{
  padding-right: 10px;
}

.new-form-field {
    margin-top: 20px;
    display: flex;
    position: relative;
    align-items: center;
}

.organization-goal-field {
  flex-direction: column;
  align-items: baseline;
}

.column {
  flex-direction: column;
}

.new-form-section {
  margin:  40px 0 20px 0;
}

.new-reward-wrapper .metadata-title {
  margin:  40px 0 40px 0;
}
.new-reward-wrapper .metadata-description {
  font-size: 16px;
  color: #8f8f8f;
  display: flex;
  position: relative;
  width: 100%;
  top: -25px;
}

.new-reward-wrapper .metadata-new-button {
  float: right;
  top: -48px;
}

.new-reward-wrapper .property-row {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}

.new-reward-wrapper .property-row > * {
  margin-right: 10px ;
}

.property-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*REWARD PREVIEW*/

.reward-preview {
  width: 50%;
  background-color: #ffff;
  height: 80%;
  padding: 10px;
}

/* REWARD MINT */
.reward-preview.grid {
  padding-top:  60px;
}

.mint-form-wrapper {
  margin-top: 80px;
}

.mint-form-wrapper > *{
  grid-column: span 4;
}

.mint-form-wrapper .MuiDataGrid-root {
    min-height: 250px;
}

.mint-form-wrapper p {
    margin-bottom: 40px;
}

.mint-form-wrapper .buttons {
    margin: 30px 0 100px;
}

.mint-form-wrapper p.loading {
  margin: 40px 0;
}

/* Actvity */
.MuiDataGrid-main {
    min-height: 400px;
    background-color: #fff;
}

/* Stats */

.stats-grid {
  grid-template-columns: repeat(3, calc((100% - 2 * var(--gap))/3));
  margin-top: 60px;
}

.custom-margin {
  margin-bottom: 25px;
  margin-top: 25px;
}

.stats-grid .card {
  /*text-align: center;*/
}

.stats-grid .canvas-wrapper {
  max-width: 160px;
  margin: 0 auto;
}

.stat-card .stats-number {
    font-size: 5vw;
    color: var(--orange);
    text-align: center;
    font-weight: bold;
    line-height: 160px;
}

.stat-card .title {
    margin-top: 20px;
}

.new-form-field-footer {
  margin-top: 20px;
  margin-block-end: 20px;
  display: flex;
  gap: 15px;
}

.new-form-data {
  margin-top:  20px;
  display: flex;
  position: relative;
  align-items: center;
  gap: 12px;
}

.course-switcher-main-container {
  display: flex;
  flex-direction: column;
}

.course-switcher-main-container > p {
  margin: 2px 0 2px 0;
  font-size: smaller;
  color: gray;
}

.course-switcher-inner-container {
  display: flex;
  align-items: center;
  gap: 2vw;
  width: max-content;
}

.course-switcher-inner-container > div {
  display: flex;
}

.course-switcher-inner-container > h2 {
  font-weight: 500;
  margin: 0;
  min-width: 225px;
 }

.menu-link {
  display: flex;
  align-items: center;
  gap: 20%;
  color: #e55e29;
}

/*social-share*/

.zero{
  margin: 0;
  padding: 0;
}
.social-share-container{
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 2vh;
 padding: 15px;
}

.container-img-share {
  padding: 0 40px;
}

.container-img-share img {
  width: 100%;
}

.container-share-icons {
  margin: 30px 0 20px;
  text-align: center;
}

.social-share-secondary-container{
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 0;
}
.social-share-horizontal-container{
  display: flex;
  align-items: center;
  gap: 10px;
}

.csv-preview {
  border: 1px solid #F75201;
  border-radius: 5px;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  margin-top: 5px;
}

.key-preview {
  font-weight: bold;
}

.label-message {
  color: #68707b;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  position: relative;
  justify-content: center;
  top: 5px;
}

.message {
  display: flex;
  position: relative;
  justify-content: center;
  top: 15px
}

.orange {
  color: var(--orange) !important;
}

.center {
  text-align: center;
}

.pulse {
  animation: pulse 8s infinite linear;
}

.center-absolutely {
  padding: 0;
  position: absolute;
  height: 20vh;
  width: 20vh;
  top: calc(50% - 10vh);
  left: calc(50% - 10vh);
  transform: translate(-50%, -50%);
}

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-image {
  height: 80%;
}

.student-profile-footer {
  background-color: #d8d4d4;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 100px;
  padding: 25px;
}

.student-profile-footer > p {
  margin: 0;
}

.student-profile-footer > a {
  color: #13171a;
  font-weight: 800;
}

.italized{
  font-style: italic;
}

.form-offers-wrapper {
  width: 500px;
  overflow: hidden;
}

.form-offers-container{
  display: flex;
  width: 100%;
  gap: 5%;
}

/* OfferCard.js */

.offer-card,
.order-card {
  background-color: var(--offerCardBackgroundColor);
  color: var(--offerCardTextColor);
  border-radius: var(--radius);
  display: flex;
  margin: 25px 0 25px 0;
}

.offer-card-image-container,
.order-card-image-container {
  border-radius: var(--radius);
  align-self: center;
}

.offer-card-image,
.order-card-image {
  border-radius: var(--radius);
  margin: 25px 9px 25px 25px;
  width: 300px;
}

/* OfferCardAccordion.js */

.accordion-content{
  display: flex;
  flex-direction: column;
  margin-top: 25px !important;
  color: var(--offerCardTextColor);
  background-color: var(--offerCardBackgroundColor);
}

.accordion-content > h3 {
  margin: 0px;
  padding: 0;
}

.accordion-content > p {
  margin: 0;
  padding: 0;
}

/* OfferCardDetails.js*/

.offer-card-details {
  margin: 0px 16px 0 16px;
}

.offer-card-details > p {
  margin: 0px;
}

/*OfferCardRewards.js*/

.offer-card-rewards {
  padding: 0 16px;
}

.offer-card-rewards > p {
  margin: 25px 16px 0 16px;
}

/* OfferCardFooter.js */

.offer-card-footer {
  margin: 0px 16px 0 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.offer-card-footer > Button {
  margin: 9px 0 36px 0;
}

.green {
  color: green;
}

/* OfferCardPreview */

.offer-card-preview {
  flex-direction: column;
}

.offer-card-image-preview {
  margin: 0;
}

.offer-card-footer-preview {
  flex-direction: column;
  align-items: flex-start;
}

/*OrderCard*/

.order-card-header > h3 {
  margin-bottom: 0;
}

.order-card-header > p {
  margin-top: 0;
  color: grey;
}

.order-card-image-container {
  flex-basis: 25%;
}

.order-card-details-container {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.order-card-button-container {
  flex-basis: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*RewardPreview*/

.reward-preview-image-container{
  position: relative;
}

.blur {
  filter: blur(15px);
  opacity: 0.6;
}

.claim-page-overlapping-container{
  position: absolute;
  top: calc(50% - 132px/2);
  left: calc(50% - 186px/2);
}

.claim-page-overlapping-image{
  width: 100px;
  margin-bottom: 10px;
}

.claim-page-overlapping-cta {
  font-size: larger;
  color: var(--textColor);
}

.bold {
  font-weight: 600;
}

.backdrop-buttons-container {
  position: fixed;
  top: 0;
  right: 28px;
}

.backdrop-buttons-container > * {
  color: black !important;
}

.theme-preview-container{
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.theme-preview-small-container:last-child{
  border: solid 1px black;
  border-left: none;
}

.theme-preview-small-container:first-child{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px black;
}

.evenly-spaced {
  width: 50%;
}

.theme-form-row{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.theme-form-row > .inner{
  gap: 2px;
}

.theme-form-upload-buttons {
  width: 100%;
  display: flex;
  margin: 10px 0px;
}

.page-top > .button-group {
  display: flex;
  gap: 20px;
}

.confirm-modal-header {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.confirm-modal-children-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.payment-provider-modal-container {
  display: grid;
  grid-auto-rows: 1fr;
}

.payment-provider-modal-logo {
  max-width: 50%;
  padding-bottom: 20px;
  align-self: center;
}

.payment-method-button {
  display: flex;
  align-items: center;
  gap: 20px;
}

.payment-method-button-icon-container {
  flex-basis: 25%;

  display: flex;
  justify-content: center;
}

.payment-method-button-icon {
  max-height: 50px;
  max-width: 75%;
}

.payment-method-button-label-container {
  flex-basis: 50%;
  text-align: start;
}

.payment-method-button-label {
  color: black;
  text-transform: none;
  font-size: large;
}

.payment-method-button-chevron-container {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
}

.utrust-payment-button-group {
  display: flex;
  gap: 20px;
}

.desktop-checkout-stepper {
  margin-bottom: 40px;
}


.invoice-form-row-container {
  display: flex;
  gap: 14px;
}

#googlepay-container {
  display: flex;
  justify-content: center;
}

.mobile-checkout-stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

.mobile-checkout-stepper-progress {
  flex-basis: 33.33%;
}

.mobile-checkout-stepper-label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mobile-checkout-stepper-label > * {
  margin: 0px;
}

.mobile-checkout-stepper-label > p {
  font-size: small;
  color: var(--orange);
}

.status-modal-login-button {
  text-align: center;
  margin: 15px;
}


.filter-show-all-content-container {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.internal_offer_selector-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title-color-theme{
  color: var(--titleColor);
}

.offer-card-background-color-theme{
  background-color: var(--offerCardBackgroundColor) !important
}

@keyframes pulse {
	0% {
		transform: scale(0.90);
	}

	25% {
		transform: scale(1);
	}

  50% {
		transform: scale(0.90);
	}

	100% {
		transform: scale(1);
	}
}

@media only screen and (min-width: 1024px) {
  .navbar,
  .mobile-checkout-stepper {
    display: none !important;
  }
}

/* Import Bootstrap and its default variables */
@import '~normalize.css/normalize.css';
@import './mui-override.css';
