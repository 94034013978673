@import url(https://fonts.googleapis.com/css2?family=Audiowide&family=Bungee&family=Roboto&display=swap);
.confetti {
    background-color: #ffffffab;
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
}
.confetti-piece {
    position: absolute;
    width: 10px;
    height: 30px;
    background: #ffd300;
    top: 0;
    opacity: 0;
}
.confetti-piece:nth-child(1) {
    left: 7%;
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 182ms;
            animation-delay: 182ms;
    -webkit-animation-duration: 2116ms;
            animation-duration: 2116ms;
}
.confetti-piece:nth-child(2) {
    left: 14%;
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 161ms;
            animation-delay: 161ms;
    -webkit-animation-duration: 2076ms;
            animation-duration: 2076ms;
}
.confetti-piece:nth-child(3) {
    left: 21%;
    -webkit-transform: rotate(-51deg);
            transform: rotate(-51deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 481ms;
            animation-delay: 481ms;
    -webkit-animation-duration: 2103ms;
            animation-duration: 2103ms;
}
.confetti-piece:nth-child(4) {
    left: 28%;
    -webkit-transform: rotate(61deg);
            transform: rotate(61deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 334ms;
            animation-delay: 334ms;
    -webkit-animation-duration: 1708ms;
            animation-duration: 1708ms;
}
.confetti-piece:nth-child(5) {
    left: 35%;
    -webkit-transform: rotate(-52deg);
            transform: rotate(-52deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 302ms;
            animation-delay: 302ms;
    -webkit-animation-duration: 1776ms;
            animation-duration: 1776ms;
}
.confetti-piece:nth-child(6) {
    left: 42%;
    -webkit-transform: rotate(38deg);
            transform: rotate(38deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 180ms;
            animation-delay: 180ms;
    -webkit-animation-duration: 2168ms;
            animation-duration: 2168ms;
}
.confetti-piece:nth-child(7) {
    left: 49%;
    -webkit-transform: rotate(11deg);
            transform: rotate(11deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 395ms;
            animation-delay: 395ms;
    -webkit-animation-duration: 2200ms;
            animation-duration: 2200ms;
}
.confetti-piece:nth-child(8) {
    left: 56%;
    -webkit-transform: rotate(49deg);
            transform: rotate(49deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 14ms;
            animation-delay: 14ms;
    -webkit-animation-duration: 1887ms;
            animation-duration: 1887ms;
}
.confetti-piece:nth-child(9) {
    left: 63%;
    -webkit-transform: rotate(-72deg);
            transform: rotate(-72deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 149ms;
            animation-delay: 149ms;
    -webkit-animation-duration: 1805ms;
            animation-duration: 1805ms;
}
.confetti-piece:nth-child(10) {
    left: 70%;
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 351ms;
            animation-delay: 351ms;
    -webkit-animation-duration: 2059ms;
            animation-duration: 2059ms;
}
.confetti-piece:nth-child(11) {
    left: 77%;
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 307ms;
            animation-delay: 307ms;
    -webkit-animation-duration: 2132ms;
            animation-duration: 2132ms;
}
.confetti-piece:nth-child(12) {
    left: 84%;
    -webkit-transform: rotate(42deg);
            transform: rotate(42deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 464ms;
            animation-delay: 464ms;
    -webkit-animation-duration: 1776ms;
            animation-duration: 1776ms;
}
.confetti-piece:nth-child(13) {
    left: 91%;
    -webkit-transform: rotate(-72deg);
            transform: rotate(-72deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
            animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 429ms;
            animation-delay: 429ms;
    -webkit-animation-duration: 1818ms;
            animation-duration: 1818ms;
}
.confetti-piece:nth-child(odd) {
    background: #7431e8;
}
.confetti-piece:nth-child(even) {
    z-index: 1;
}
.confetti-piece:nth-child(4n) {
    width: 5px;
    height: 12px;
    -webkit-animation-duration: 3000ms;
            animation-duration: 3000ms;
}
.confetti-piece:nth-child(3n) {
    width: 3px;
    height: 10px;
    -webkit-animation-duration: 3500ms;
            animation-duration: 3500ms;
    -webkit-animation-delay: 1000ms;
            animation-delay: 1000ms;
}
.confetti-piece:nth-child(4n-7) {
  background: red;
}
@-webkit-keyframes makeItRain {
    from {opacity: 0;}
    50% {opacity: 1;}
    to {-webkit-transform: translateY(800px);transform: translateY(800px);}
}
@keyframes makeItRain {
    from {opacity: 0;}
    50% {opacity: 1;}
    to {-webkit-transform: translateY(800px);transform: translateY(800px);}
}
.badge-card.double {
  grid-column: span 2;
}

.badge-card img {
    max-height: 160px;
    object-fit: contain;
    max-width: 100%;
}

.badge-card.double img {
  max-height: 240px;
}
.badge-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: auto;
  color: #1a1d1f;
}

.badge-image {
  text-align: center;
}

.backdrop-badge-image {
  max-width: 65%;
  max-height: 80% !important;
}

.badge-stats {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;

  min-height: 60px;
  align-items: center;
}

.badge-stats .stats p {
    margin: 0;
}

.badge-stats .stats .stat-number {
  text-align: center;
  /*vertical-align: top;*/
  font-size: 20px;
  /*line-height: auto;*/
  margin-bottom: 8px;
  color: #8f8f8f;
}

.badge-stats .stats .stat-title {
  text-align: center;
  /*vertical-align: top;*/
  font-size: 12px;
  /*line-height: auto;*/
  color: #c4c4c4;
}
.stat-title {
  text-transform: uppercase;
}

.badge-stats .button-wrapper {
  grid-column: span 2;
  text-align: center;
}

.badge-labels {
  position: absolute;
}

.badge-labels .template,
.badge-labels .public {
  border-radius: 3px;
  color: var(--orange);
  border-color: var(--orange);
  display: inline-flex;
  position: relative;
  left: -10px;
  top: -10px;
  margin-right: 5px;
  font-size: 12px;
  height: 22px;
  border: 2px solid;
  font-weight: 700;
}
.badge-labels .preview {
  border-radius: 3px;
  color: var(--orange);
  border-color: var(--orange);
  display: inline-flex;
  position: relative;
  left: 10px;
  top: 10px;
  margin-right: 5px;
  font-size: 12px;
  height: 22px;
  border: 2px solid;
  font-weight: 700;

}

.badge-labels span {
  padding: 5px;
}

/* Actions */
.badge-actions-wrapper {
    position: absolute;
    right: 2px;
    top:  2px;
}

.badge-actions-menu .MuiMenuItem-root > svg {
  padding-right: 15px;
}

/* Video */

.video-thumb {
    max-width: 100%;
    margin: 25px 0px 15px 0px;
  }


.container-video {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.video {
  max-width: 100%;
}

.button-play{
  display: flex;
  position: absolute;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-margin-start: 40px;
          margin-inline-start: 40px;
  right: 25px;
}

.button-audio {
  display: flex;
  position: absolute;
  cursor: pointer;
  align-self: normal;
  left: 10px;
  top: 10px;
  border: none;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.grey{
  background-color: #ebebeb !important;
}

/* Reward Preview */

#reward-preview .title{
  display: flex;
  justify-content: center;
  text-align: center; /* mobile */
  margin-top: 30px;
  color: var(--titleColor);
  font-size: var(--titleSize);
  line-height: 40px;
  font-weight: bold;
}

#reward-preview .badge-image {
  width: 100%;
  max-width: inherit;
}

#reward-preview .certificate-image {
  width: 550px;
  max-width: inherit;
  -webkit-margin-before: 50px;
          margin-block-start: 50px;
}

#reward-preview .student,
#reward-preview .creator {
  font-size: var(--titleSize);
  font-weight: bold;
  color: var(--textColor);
  margin-top: 15px;
}

#reward-preview .text {
  color: var(--textColor);
  font-size: var(--subtitleSize);
}

#reward-preview .onboarding {
  color: var(--textColor);
}

#reward-preview .media-container {
  width: 100%;
  text-align: center;
  position: relative;
}

#reward-preview .media-container > * {
  max-width: 100%;
}

#reward-preview .collection-container {
  text-align: center;
}

#reward-preview .collection-container .mobile{
  display: none;
}

#reward-preview .organization-desktop{
  margin-top: 10px;
  color: var(--textColor);
  font-size: 20px;
  font-weight: bold;
}
#reward-preview .organization-mobile{
  display: none;
}

#reward-preview .course-desktop{
  margin-top: 10px;
  font-size: 18px;
  line-height: auto;
  color: var(--textColor);
}

#reward-preview .course-desktop a {
  color: var(--textColor);
  -webkit-text-decoration-color: var(--textColor);
          text-decoration-color: var(--textColor);
  padding-left:  10px;
}

#reward-preview .share-container,
#reward-preview .offers-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#reward-preview .share-container button img {
  width: 24px;
}

#reward-preview .note {
  color: #68707b;
  text-align: center;
  font-size: 14px;
}

#reward-preview .description-container {
  margin-top: 80px;
  white-space: pre-line;
  font-size: var(--descriptionSize);
  line-height: 1.4em;
  color: var(--textColor);
}

#reward-preview .description-container h3 {
  margin-bottom: -15px;
}

#reward-preview .description-container p {
  margin-bottom: 0px;
}

.description-container a {
  color: var(--textColor);
  text-decoration: underline;
}

#reward-preview .metadata-container {
  margin-top: 80px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px 14px;
}

#reward-preview .metadata-preview {
  border-radius: 10px;
  background-color: var(--metadataBoxColor);
  color: var(--metadataTextColor);
  padding: 14px;
}

#reward-preview .metadata-preview h4,
#reward-preview .metadata-preview p {
  margin: 0;
}

#reward-preview .metadata-preview h4{
  margin-bottom: 14px;
}


#reward-preview .block h4 {
  margin:  0;
}

#reward-preview .block p {
  color: #68707b;
  margin: 10px 0 0;
}


#reward-preview .skeletonBox {
  display: flex;
  justify-content: center;
}

/* CLAIM ACTIONS */
.actions-container {
  margin-top: 55px;
}

.grid.buy-actions-container {
  width: 100%;
  grid-template-columns: 1fr 120px 1fr;
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  font-size: 14px;
}

.buy-actions-amount {
  border-top: 1px solid var(--textColor);
  border-bottom: 1px solid var(--textColor);
  display: grid;
  grid-template-columns: 26% 40% 26%;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  text-align: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.buy-actions-amount > .amount-selector {
  border-left: 1px solid var(--textColor);
  border-right: 1px solid var(--textColor);
  background-color: rgba(255, 255, 255, 0.1);
}

.buy-actions-amount > .amount-selector .MuiAmountIconButton:not(.Mui-disabled) {
  color: var(--textColor) !important;
}

.buy-actions-container .price {
  text-align: left !important;
  font-size: 40px;
  font-weight: 400;
}

.buy-button.MuiButton-contained {
  box-shadow: none;
  height: 100%;
  border-radius: 0;
}

.gift-checkbox {
  margin-top: -5px;
}

.gift-checkbox span:not(.Mui-checked) svg.MuiSvgIcon-root {
  color: rgba(var(--textColor), 0.6);
}

.gift-checkbox .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}

/* USER NOTICE */

.user-notice {
  text-align: center;
  margin-top: 80px;
  padding-bottom: 0px;
  font-size: var(--descriptionSize);
}

/* SHARE MODAL */

.share-label {
  font-family: var(--fontFamily);
}

.share-modal-p {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.share-modal-label {
  text-align: center;
  margin: 0;
}

.description-share-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.button-container{
  display: flex;
  position: relative;
  justify-content: center;
  padding: 15px 0px 30px 0px;
}

.claim-settings-container{
  color: var(--textColor);
  padding-top: 10px;
}


@media only screen and (max-width: 1024px) {
  .grid.buy-actions-container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'price amount'
        'supply total'
        'button button'
        'gift gift';
  }

  .grid.buy-actions-container .price {
    grid-area: price;
  }

  .grid.buy-actions-container .amount {
    grid-area: amount;
    justify-self: right;
  }

  .buy-actions-amount {
    width: 140px;
    grid-template-columns: 30% 40% 30%;
  }

  .grid.buy-actions-container .button {
    grid-area: button;
    text-align: center;
    margin-top: 50px;
  }

  .grid.buy-actions-container .button .buy-button {
    max-width: 240px;
    padding: 14px 28px;
  }

  .grid.buy-actions-container .supply {
    grid-area: supply;
  }

  .grid.buy-actions-container .total {
    grid-area: total;
    width: 140px;
    justify-self: right;
  }

  .grid.buy-actions-container .gift-checkbox {
    grid-area: gift;
    max-width: 240px;
    margin: 0 auto;
    width: 100%;
  }

}

#modal-image{
    z-Index: 0;
    object-fit: cover;
    max-width:60%;
    max-height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.customization-bar{
    display: flex;
    grid-gap: 1%;
    gap: 1%;
    align-items: center;
}

.item-selector{
    display: flex;
    align-items: center;
    grid-gap: 0.5%;
    gap: 0.5%;
} 

.dynamic-box{
    border: solid 2px transparent;
}
  
.dynamic-box:hover{
    cursor: move;
    border: solid 2px #e55e29;
    border-radius: 5px;
}

.left{
    text-align: left;
}

#payment-message {
  color: rgb(168, 16, 16);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* .payment-buttons and links */
.payment-button {
  background: var(--orange);
  font-family: Arial, sans-serif;
  color: var(--buttonTextColor);
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.payment-button:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}

.payment-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* payment-spinner/processing state, errors */
.payment-spinner,
.payment-spinner:before {
  border-radius: 50%;
}

.payment-spinner {
  color: rgb(168, 16, 16);
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.payment-spinner:before {
  position: absolute;
  content: '';
}

.payment-spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: white;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    /* width: 80vw; */
    min-width: initial;
  }
}

/**
* Public Pages Layouts (sidebar & centered)
* Claim, Preview, Login, Signup, Forgot Password, etc...
**/

#root, #root > div[data-rk], html, body {
    height: 100%;
    margin: 0;
}

.login .left-pane {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .left-pane .wrapper {
  grid-row-gap: 30px;
  grid-row-gap: 30px;
  row-gap: 30px
}

/* LAYOUT SPECIFIC */
.layout-sidebar {
  display: flex;
  height: 100%;
}

.layout-centered .left-pane {
  background-image: var(--supportImage);
  background-repeat: no-repeat;
  background-attachment: local;
  /* background-size: 50%; */
  background-position: center;
}

/* Left Pane */
.left-pane {
  text-align: left;
  flex: 1 0 60%;
  overflow-y: auto;
  box-shadow: 0 20px 110px 0 rgb(0 12 44 / 8%);
  z-index: 2;
  background-color: var(--primaryBackgroundColor);
  justify-content: center;
}

.left-pane .wrapper{
  width: 600px;
  display: flex;
  flex-direction: column;
  padding: var(--paddingPage);
  margin: 0 auto;
}

.left-pane .offer-wrapper{
  width: 1200px;
}

.left-pane .logo img{
  max-width: 100%;
}

.left-pane .header{
  margin-top: 60px;
}

.left-pane .header p{
  margin-top: 0px;
}

.left-pane .wrapper .content {
  color: var(--textColor);
  flex-direction: column;
  justify-content: center;
  padding: 0;
  flex: 1 0 auto;
}

.left-pane .wrapper .content form {
  max-width: 400px;
  margin-top: 64px;
}

.left-pane .wrapper .content form .MuiFormControlLabel-root {
  margin-top: 16px;
  margin-bottom: 8px;
}

/* Right Pane */
.right-pane {
  flex: 1 0 40%;
  position: relative;
  z-index: 1;

  background-image: var(--supportImage);
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: bottom right;
}

/* ASSORTED */

.no-account {
  margin-top: 120px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

.no-account a,
.label-terms a,
.label-privacy a,
.no-account-modal a {
  color: var(--textColor);
  text-decoration: underline;
}

.footer-labels {
  letter-spacing: 0.2px;
  color: #68707b;
  font-size: 14px;
  font-weight: 300;
  margin-top: 30px;
}

.footer-labels p {
  display: inline-block;
  padding: 0 10px;
  margin: 0;
}

.footer-labels p:first-child {
  padding-left: 0px;
}

.footer-labels p:not(:last-child) {
  border-right: 1px solid;
}

.label-in-checkbox a {
  color: #000000;
  text-decoration: underline;
}



@font-face {
  font-family: 'Mont';
  src: local('Mont-Light'), url(/static/media/Mont-Light.56e27104.otf) format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'Mont';
  src: local('Mont-Regular'), url(/static/media/Mont-Regular.1d26d89f.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Mont';
  src: local('Mont-Bold'), url(/static/media/Mont-Bold.03c8c2f6.otf) format('opentype');
  font-weight: 600;
}


/* ALL THEMES */

.theme .share-container .MuiButton-outlined:not(.Mui-disabled, .MuiButton-outlinedWarning) {
  border-color: var(--textColor) !important;
  color: var(--textColor) !important;
}

/* THEME SLB */
.theme-slb {
  font-family: 'Mont', sans-serif;
  font-weight: 200;
}

.theme-slb .left-pane .wrapper {
  padding-top: 40px;
}

.theme-slb .logo {
  margin-left: -160px;
}

.theme-slb .logo img{
  width: 60px;
}

.theme-slb .left-pane {
  background-size: cover;
}

.theme-slb .header {
  font-size: 40px;
  font-weight: 200;
  text-align: center;
}

.theme-slb .header p {
  margin-bottom: 0;
}

.theme-slb #reward-preview .title {
  font-weight: 600;
}

.theme-slb .media-container {
  margin-top: 80px;
}

.theme-slb .metadata-preview {
  border: 1px solid var(--textColor);
}

.theme-slb .gift-checkbox .MuiTypography-root {
  font-weight: 200;
  font-family: 'Mont', sans-serif;
  font-size: var(--fontSize);
}

.theme-slb .description-container h2 {
  font-size: 40px;
  font-weight: 200;
}

.theme-slb .exclamation-icon svg {
  color: var(--textColor);
}

@media only screen and (max-width: 1024px) {
  .theme-slb .logo {
    margin-left: 0;
  }

  .theme-slb .header {
    font-size: 28px;
  }
}

/* THEME TVI */
@media only screen and (max-width: 1900px) {
  .theme-tvi .left-pane {
    background-size: 75%;
  }
}

@media only screen and (max-width: 1400px) {
  .theme-tvi .left-pane {
    background-size: 95%;
  }
}

@media only screen and (max-width: 1000px) {
  .theme-tvi .left-pane {
    background-image: none;
  }
}

/* THEME SAMSYS */
.theme-samsys .right-pane {
  background-position: 0 0;
  background-size: cover;
}

/* THEME FCT NOVA */
.theme-fct .right-pane {
  background-position: 0 0;
  background-size: cover;
}

.left-pane .wrapper .content {
  position: relative;
}

#explosion {
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: white;
  -webkit-filter: blur(60px);
          filter: blur(60px);

  position: absolute;
  top: 130px;
  left: 0;

  -webkit-animation: box-explosion 2.5s linear 0s 1 normal none;

          animation: box-explosion 2.5s linear 0s 1 normal none;

}

#backdrop{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 120vh;

  -webkit-animation: backdrop-pop 1.5s linear 2.5s 1 normal forwards;

          animation: backdrop-pop 1.5s linear 2.5s 1 normal forwards;
}

div#backdrop .grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 60px;
}

.revealed-token {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.revealed-token img {
  width: 100%;
  -webkit-transform:rotateX(90deg);
          transform:rotateX(90deg);

  -webkit-animation: card-flip 1s linear 5s 1 normal forwards;

          animation: card-flip 1s linear 5s 1 normal forwards;
}

.revealed-token video {
  width: 100%;
}

.revealed-token .img-wrapper,
.revealed-token .video-wrapper {
  width: -webkit-fill-available;
}

.revealed-token .img-wrapper:hover {
  -webkit-animation: card-shake 0.5s linear 0s 1 normal none;
          animation: card-shake 0.5s linear 0s 1 normal none;
}

.revealed-token .title {
  color: white;
  font-size: 2em;
  margin: 20px;
  text-align: center;
}

.revealed-token a.button {
  color: white;
  background-color: #e80233;
  padding: 10px 20px;
  display: inline-block;
}

.revealed-token .link {
  min-height: 40px;
}

.revealed-token .future {
  color: rgb(199, 199, 199);
  font-style: italic;
}

@media only screen and (max-width: 1024px) {
  div#backdrop .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: 100%;
  }

  .revealed-token:nth-child(1),
  .revealed-token:nth-child(3) {
    display: none;
  }
}


@-webkit-keyframes box-explosion {
  0% {
    -webkit-transform:scale(0);
            transform:scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform:scale(1);
            transform:scale(1);
    opacity: 1;
  }
}


@keyframes box-explosion {
  0% {
    -webkit-transform:scale(0);
            transform:scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform:scale(1);
            transform:scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes backdrop-pop {
  0% {
    opacity:0;
    background-color: white;
  }
  45% {
    background-color:rgb(255, 92, 92);
    opacity:0.8;
  }
  55% {
    background-color:rgb(253, 186, 186);
    opacity:0.6;
  }
  100% {
    background-color:rgb(55, 52, 52);
    opacity:1;
  }
}

@keyframes backdrop-pop {
  0% {
    opacity:0;
    background-color: white;
  }
  45% {
    background-color:rgb(255, 92, 92);
    opacity:0.8;
  }
  55% {
    background-color:rgb(253, 186, 186);
    opacity:0.6;
  }
  100% {
    background-color:rgb(55, 52, 52);
    opacity:1;
  }
}

@-webkit-keyframes card-flip {
  0% {
    -webkit-transform:
    matrix3d(1,0,0.00,0,0.00,0,1.00,0.008,0,-1,0,0,0,0,0,1) scale(0.5);
            transform:
    matrix3d(1,0,0.00,0,0.00,0,1.00,0.008,0,-1,0,0,0,0,0,1) scale(0.5);
  }
  100% {
    -webkit-transform:rotateX(0deg) scale(1);
            transform:rotateX(0deg) scale(1);
  }
}

@keyframes card-flip {
  0% {
    -webkit-transform:
    matrix3d(1,0,0.00,0,0.00,0,1.00,0.008,0,-1,0,0,0,0,0,1) scale(0.5);
            transform:
    matrix3d(1,0,0.00,0,0.00,0,1.00,0.008,0,-1,0,0,0,0,0,1) scale(0.5);
  }
  100% {
    -webkit-transform:rotateX(0deg) scale(1);
            transform:rotateX(0deg) scale(1);
  }
}

@-webkit-keyframes card-shake {
  0% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
    -webkit-transform-origin:50% 100%;
            transform-origin:50% 100%;
  }
  10% {
    -webkit-transform:rotate(1deg);
            transform:rotate(1deg);
  }
  20% {
    -webkit-transform:rotate(-2deg);
            transform:rotate(-2deg);
  }
  30% {
    -webkit-transform:rotate(2deg);
            transform:rotate(2deg);
  }
  40% {
    -webkit-transform:rotate(-2deg);
            transform:rotate(-2deg);
  }
  50% {
    -webkit-transform:rotate(2deg);
            transform:rotate(2deg);
  }
  60% {
    -webkit-transform:rotate(-2deg);
            transform:rotate(-2deg);
  }
  70% {
    -webkit-transform:rotate(2deg);
            transform:rotate(2deg);
  }
  80% {
    -webkit-transform:rotate(-1deg);
            transform:rotate(-1deg);
  }
  90% {
    -webkit-transform:rotate(1deg);
            transform:rotate(1deg);
  }
  100% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
    -webkit-transform-origin:50% 100%;
            transform-origin:50% 100%;
  }
}

@keyframes card-shake {
  0% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
    -webkit-transform-origin:50% 100%;
            transform-origin:50% 100%;
  }
  10% {
    -webkit-transform:rotate(1deg);
            transform:rotate(1deg);
  }
  20% {
    -webkit-transform:rotate(-2deg);
            transform:rotate(-2deg);
  }
  30% {
    -webkit-transform:rotate(2deg);
            transform:rotate(2deg);
  }
  40% {
    -webkit-transform:rotate(-2deg);
            transform:rotate(-2deg);
  }
  50% {
    -webkit-transform:rotate(2deg);
            transform:rotate(2deg);
  }
  60% {
    -webkit-transform:rotate(-2deg);
            transform:rotate(-2deg);
  }
  70% {
    -webkit-transform:rotate(2deg);
            transform:rotate(2deg);
  }
  80% {
    -webkit-transform:rotate(-1deg);
            transform:rotate(-1deg);
  }
  90% {
    -webkit-transform:rotate(1deg);
            transform:rotate(1deg);
  }
  100% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
    -webkit-transform-origin:50% 100%;
            transform-origin:50% 100%;
  }
}

/* Primary Button */

.MuiButton-contained:not(.Mui-disabled) {
  background-color: var(--orange) !important;
  color: var(--buttonTextColor) !important;
}

.MuiButton-outlined:not(.Mui-disabled, .MuiButton-outlinedWarning) {
    border-color: var(--orange) !important;
    color: var(--orange) !important;
}
.MuiButton-outlined:hover {
  background-color: #f7520114!important;
}

.MuiButton-text {
  color: var(--orange) !important;
}

.MuiButton-text:hover {
  background-color: #4f4f4f14!important;
}

/* Checkbox */
.Mui-checked {
    color: var(--orange) !important;
}

/* Menu Item Selected */
.MuiListItemButton-root.Mui-selected {
  background-color: #08031d21 !important;
}

/* Tab Item */
.MuiTab-root {
  text-transform: none !important;
  font-size: 16px !important;
}

.MuiTab-root.Mui-selected {
  color: #000 !important;
}

.MuiTabs-indicator {
  background-color: var(--orange) !important;
}

.MuiMenu-button {
  display: flex !important;
  justify-content: space-between !important;
  width: '100%' !important;
  grid-gap: 10% !important;
  gap: 10% !important;
  text-transform: unset !important;
}

.MuiButton-outlinedWarning {
  background-color: #FCCBB2 !important;
  color: #CC0000 !important;
}

.MuiDialogActions-root {
  display: flex !important;
  position: relative !important;
  justify-content: center !important;
}

.MuiAmountIconButton{
  color: var(--orange) !important;
}

.MuiAmountIconButtonDisabled{
  color: grey !important;
}
:root {
  --orange: #F75201;
  --gap:  30px;
  --radius: 10px;
  /*  WHITELABEL PROPERTIES*/
  --primaryBackgroundColor: #ffff;
  --titleColor: #1a1d1f;
  --buttonTextColor: #ffff;
  --textColor: #13171a;
  --courseFontColor: #1a1d1f;
  --metadataTextColor: #1a1d1f;
  --metadataBoxColor: #ebebeb;
  --secondaryBackgroundColor: #fdecef;
  --supportImage: url(/static/media/shapes.eba690fe.svg);
  --paddingPage: 80px 60px 80px;
  --titleSize: 30px;
  --subtitleSize: 16px;
  --bannerSize: 220px;
  --descriptionSize: 16px;
  --fontFamily: 'Syne', sans-serif;
  --offerCardBackgroundColor: #ffff;
  --offerCardTextColor: #13171a;
}

a {
  text-decoration: none;
}

body {
	background-color: #fdecef;
	background-color: var(--secondaryBackgroundColor);
  font-family: 'Syne', sans-serif;
  font-family: var(--fontFamily);
}

.grid {
    display: grid;
    grid-gap: 30px;
    margin: 0 auto;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  border-radius: var(--radius);
  padding: 30px; /* mudança no padding aqui */
  position: relative;
}

.card .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: auto;
    color: #1a1d1f;
}

.action-card {
    text-align: center;
    background-color: #ebebeb;
    color: #8f8f8f;
    margin-top: 30px;
}

.action-card-description {
display: none;
}

.action-card:hover .action-card-description  {
display: block;
}

.action-card:hover {
  background-color: #dbdbdb;
  transition: 0.5s;
  -webkit-transform: 0.2s translateY(-3px);
          transform: 0.2s translateY(-3px);
  box-shadow: 0 10px 20px  rgba(0, 0, 0, 0.2);
}


a .action-card p {
  font-size: 14px;
  color: #8f8f8f;
}

.page-top,
.badges-grid {
  grid-template-columns: repeat(4, calc((100% - 3 * 30px)/4));
  grid-template-columns: repeat(4, calc((100% - 3 * var(--gap))/4));
}

.vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabpanel-menu {
  margin-top: 50px;
  margin-bottom: 25px;
}

.tabpanel {
  padding-bottom: 100px ;
}

.data-table-header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0 10px 0;
}

.align-right {
  margin-left: 95%;
}

.table-subHeader-button {
  background-color: #fdecef !important;
  background-color: var(--secondaryBackgroundColor) !important;
  color: black !important;
  width: 100%;
}

/* LAYOUT */

.drawer-logo {
  min-height: 100px !important;
}

.drawer-logo img {
  width: 100%;
}

main.grid {
  grid-template-columns: repeat(10, 10%);
  grid-gap: 0;
  min-width: calc(100% - 260px);
}

main .content {
  grid-column: 2 / span 8;
}

.back-wrapper {
  text-align: center;
  padding-top: 78px;
}

.back-wrapper button {
  background-color: #fff;
}

.container-menu-switcher {
  width: 100%;
  display: flex;
  margin: auto;
  margin-bottom: 0;
}

.switcher-menu-item {
  width: 100%;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-start;
}

.organization-name {
  color: #1a1d1f;
  padding: 5px;
  margin: 0px;
  display: flex;
}

.organization-button {
  display: flex;
  position: relative;
  color: #1a1d1f;
  padding: 0;
  margin: 0;
  left: 14px;
}

.organization-button-logout{
  display: flex;
  position: relative;
  color: #1a1d1f;
  padding: 0;
  margin: 0;
  right: 60px;
}

.menu-name {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
}

.menu-email {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  color: #8F8F8F;
}

.container-menu {
  display: flex;
  position: relative;
  flex-direction: column;

}

/* ELEMENTS */

.page-actions {
  text-align: right;
}

.settings-menu-button.MuiButton-contained {
  min-width: 46px;
  background-color: #b3b3b3 !important;
}

.settings-menu-button .MuiButton-endIcon {
  margin: 0;
}

.container-preview {
  margin-top: 20px;
  display: flex;
  position: relative;
  left: -15px;
}

/* DASHBOARD */

.page-top {
  margin-top: 80px;
}
.page-profile-pic {
  text-align: center;
  }
.page-profile-pic-wrapper {
  background-color: white;
  border-radius: 500px;
  padding: 35px;
  /* max-height: 100px; */
  text-align: center;
  border: 1px solid #d4d4d4;
  margin: 0 auto;
  display: inline-block;
}

.page-profile-pic img {
  height: 100px;
}

.page-info {
  grid-column: span 2;
}

.page-info .page-label {
  opacity: 0.6;
  font-size: 14px;
  color: #333333;
}
.page-info .page-description {
  font-size: 16px;
  color: #8f8f8f;
}
.page-info .page-name {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  margin: 10px 0;
}

.page-stat {
  grid-column: -2;
}

.page-stat .stat-card .stats-number {
  line-height: 100px;
}

.tokens-overview {
    text-align: left;
}

.tokens-overview .text-1 {
  font-size: 14px;
  color: #b2b2b2;
}
.tokens-overview .text-2 {
  font-size: 24px;
  color: #29292b;
    margin: 10px 0;
}
.tokens-overview .text-3 {
  font-size: 12px;
  color: #333333;
}

/* REWARD FORM */

.reward-creation-container{
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  margin-bottom: 20px;
  width: 100%;
}

#admin-rewards-add {
  width: 50%;
}

.new-reward-wrapper .reward-image{
  padding-right: 10px;
}

.new-form-field {
    margin-top: 20px;
    display: flex;
    position: relative;
    align-items: center;
}

.organization-goal-field {
  flex-direction: column;
  align-items: baseline;
}

.column {
  flex-direction: column;
}

.new-form-section {
  margin:  40px 0 20px 0;
}

.new-reward-wrapper .metadata-title {
  margin:  40px 0 40px 0;
}
.new-reward-wrapper .metadata-description {
  font-size: 16px;
  color: #8f8f8f;
  display: flex;
  position: relative;
  width: 100%;
  top: -25px;
}

.new-reward-wrapper .metadata-new-button {
  float: right;
  top: -48px;
}

.new-reward-wrapper .property-row {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}

.new-reward-wrapper .property-row > * {
  margin-right: 10px ;
}

.property-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*REWARD PREVIEW*/

.reward-preview {
  width: 50%;
  background-color: #ffff;
  height: 80%;
  padding: 10px;
}

/* REWARD MINT */
.reward-preview.grid {
  padding-top:  60px;
}

.mint-form-wrapper {
  margin-top: 80px;
}

.mint-form-wrapper > *{
  grid-column: span 4;
}

.mint-form-wrapper .MuiDataGrid-root {
    min-height: 250px;
}

.mint-form-wrapper p {
    margin-bottom: 40px;
}

.mint-form-wrapper .buttons {
    margin: 30px 0 100px;
}

.mint-form-wrapper p.loading {
  margin: 40px 0;
}

/* Actvity */
.MuiDataGrid-main {
    min-height: 400px;
    background-color: #fff;
}

/* Stats */

.stats-grid {
  grid-template-columns: repeat(3, calc((100% - 2 * 30px)/3));
  grid-template-columns: repeat(3, calc((100% - 2 * var(--gap))/3));
  margin-top: 60px;
}

.custom-margin {
  margin-bottom: 25px;
  margin-top: 25px;
}

.stats-grid .card {
  /*text-align: center;*/
}

.stats-grid .canvas-wrapper {
  max-width: 160px;
  margin: 0 auto;
}

.stat-card .stats-number {
    font-size: 5vw;
    color: #F75201;
    color: var(--orange);
    text-align: center;
    font-weight: bold;
    line-height: 160px;
}

.stat-card .title {
    margin-top: 20px;
}

.new-form-field-footer {
  margin-top: 20px;
  -webkit-margin-after: 20px;
          margin-block-end: 20px;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.new-form-data {
  margin-top:  20px;
  display: flex;
  position: relative;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.course-switcher-main-container {
  display: flex;
  flex-direction: column;
}

.course-switcher-main-container > p {
  margin: 2px 0 2px 0;
  font-size: smaller;
  color: gray;
}

.course-switcher-inner-container {
  display: flex;
  align-items: center;
  grid-gap: 2vw;
  gap: 2vw;
  width: -webkit-max-content;
  width: max-content;
}

.course-switcher-inner-container > div {
  display: flex;
}

.course-switcher-inner-container > h2 {
  font-weight: 500;
  margin: 0;
  min-width: 225px;
 }

.menu-link {
  display: flex;
  align-items: center;
  grid-gap: 20%;
  gap: 20%;
  color: #e55e29;
}

/*social-share*/

.zero{
  margin: 0;
  padding: 0;
}
.social-share-container{
 display: flex;
 flex-direction: column;
 align-items: center;
 grid-gap: 2vh;
 gap: 2vh;
 padding: 15px;
}

.container-img-share {
  padding: 0 40px;
}

.container-img-share img {
  width: 100%;
}

.container-share-icons {
  margin: 30px 0 20px;
  text-align: center;
}

.social-share-secondary-container{
  display: flex;
  flex-direction: column;
  justify-items: center;
  grid-gap: 0;
  gap: 0;
}
.social-share-horizontal-container{
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.csv-preview {
  border: 1px solid #F75201;
  border-radius: 5px;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  margin-top: 5px;
}

.key-preview {
  font-weight: bold;
}

.label-message {
  color: #68707b;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  position: relative;
  justify-content: center;
  top: 5px;
}

.message {
  display: flex;
  position: relative;
  justify-content: center;
  top: 15px
}

.orange {
  color: #F75201 !important;
  color: var(--orange) !important;
}

.center {
  text-align: center;
}

.pulse {
  -webkit-animation: pulse 8s infinite linear;
          animation: pulse 8s infinite linear;
}

.center-absolutely {
  padding: 0;
  position: absolute;
  height: 20vh;
  width: 20vh;
  top: calc(50% - 10vh);
  left: calc(50% - 10vh);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-image {
  height: 80%;
}

.student-profile-footer {
  background-color: #d8d4d4;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 100px;
  padding: 25px;
}

.student-profile-footer > p {
  margin: 0;
}

.student-profile-footer > a {
  color: #13171a;
  font-weight: 800;
}

.italized{
  font-style: italic;
}

.form-offers-wrapper {
  width: 500px;
  overflow: hidden;
}

.form-offers-container{
  display: flex;
  width: 100%;
  grid-gap: 5%;
  gap: 5%;
}

/* OfferCard.js */

.offer-card,
.order-card {
  background-color: #ffff;
  background-color: var(--offerCardBackgroundColor);
  color: #13171a;
  color: var(--offerCardTextColor);
  border-radius: 10px;
  border-radius: var(--radius);
  display: flex;
  margin: 25px 0 25px 0;
}

.offer-card-image-container,
.order-card-image-container {
  border-radius: 10px;
  border-radius: var(--radius);
  align-self: center;
}

.offer-card-image,
.order-card-image {
  border-radius: 10px;
  border-radius: var(--radius);
  margin: 25px 9px 25px 25px;
  width: 300px;
}

/* OfferCardAccordion.js */

.accordion-content{
  display: flex;
  flex-direction: column;
  margin-top: 25px !important;
  color: #13171a;
  color: var(--offerCardTextColor);
  background-color: #ffff;
  background-color: var(--offerCardBackgroundColor);
}

.accordion-content > h3 {
  margin: 0px;
  padding: 0;
}

.accordion-content > p {
  margin: 0;
  padding: 0;
}

/* OfferCardDetails.js*/

.offer-card-details {
  margin: 0px 16px 0 16px;
}

.offer-card-details > p {
  margin: 0px;
}

/*OfferCardRewards.js*/

.offer-card-rewards {
  padding: 0 16px;
}

.offer-card-rewards > p {
  margin: 25px 16px 0 16px;
}

/* OfferCardFooter.js */

.offer-card-footer {
  margin: 0px 16px 0 16px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.offer-card-footer > Button {
  margin: 9px 0 36px 0;
}

.green {
  color: green;
}

/* OfferCardPreview */

.offer-card-preview {
  flex-direction: column;
}

.offer-card-image-preview {
  margin: 0;
}

.offer-card-footer-preview {
  flex-direction: column;
  align-items: flex-start;
}

/*OrderCard*/

.order-card-header > h3 {
  margin-bottom: 0;
}

.order-card-header > p {
  margin-top: 0;
  color: grey;
}

.order-card-image-container {
  flex-basis: 25%;
}

.order-card-details-container {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.order-card-button-container {
  flex-basis: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*RewardPreview*/

.reward-preview-image-container{
  position: relative;
}

.blur {
  -webkit-filter: blur(15px);
          filter: blur(15px);
  opacity: 0.6;
}

.claim-page-overlapping-container{
  position: absolute;
  top: calc(50% - 132px/2);
  left: calc(50% - 186px/2);
}

.claim-page-overlapping-image{
  width: 100px;
  margin-bottom: 10px;
}

.claim-page-overlapping-cta {
  font-size: larger;
  color: #13171a;
  color: var(--textColor);
}

.bold {
  font-weight: 600;
}

.backdrop-buttons-container {
  position: fixed;
  top: 0;
  right: 28px;
}

.backdrop-buttons-container > * {
  color: black !important;
}

.theme-preview-container{
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.theme-preview-small-container:last-child{
  border: solid 1px black;
  border-left: none;
}

.theme-preview-small-container:first-child{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px black;
}

.evenly-spaced {
  width: 50%;
}

.theme-form-row{
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.theme-form-row > .inner{
  grid-gap: 2px;
  gap: 2px;
}

.theme-form-upload-buttons {
  width: 100%;
  display: flex;
  margin: 10px 0px;
}

.page-top > .button-group {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.confirm-modal-header {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.confirm-modal-children-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.payment-provider-modal-container {
  display: grid;
  grid-auto-rows: 1fr;
}

.payment-provider-modal-logo {
  max-width: 50%;
  padding-bottom: 20px;
  align-self: center;
}

.payment-method-button {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.payment-method-button-icon-container {
  flex-basis: 25%;

  display: flex;
  justify-content: center;
}

.payment-method-button-icon {
  max-height: 50px;
  max-width: 75%;
}

.payment-method-button-label-container {
  flex-basis: 50%;
  text-align: start;
}

.payment-method-button-label {
  color: black;
  text-transform: none;
  font-size: large;
}

.payment-method-button-chevron-container {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
}

.utrust-payment-button-group {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.desktop-checkout-stepper {
  margin-bottom: 40px;
}


.invoice-form-row-container {
  display: flex;
  grid-gap: 14px;
  gap: 14px;
}

#googlepay-container {
  display: flex;
  justify-content: center;
}

.mobile-checkout-stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

.mobile-checkout-stepper-progress {
  flex-basis: 33.33%;
}

.mobile-checkout-stepper-label {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.mobile-checkout-stepper-label > * {
  margin: 0px;
}

.mobile-checkout-stepper-label > p {
  font-size: small;
  color: #F75201;
  color: var(--orange);
}

.status-modal-login-button {
  text-align: center;
  margin: 15px;
}


.filter-show-all-content-container {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.internal_offer_selector-container{
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.title-color-theme{
  color: #1a1d1f;
  color: var(--titleColor);
}

.offer-card-background-color-theme{
  background-color: #ffff !important;
  background-color: var(--offerCardBackgroundColor) !important
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.90);
		        transform: scale(0.90);
	}

	25% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

  50% {
		-webkit-transform: scale(0.90);
		        transform: scale(0.90);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.90);
		        transform: scale(0.90);
	}

	25% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

  50% {
		-webkit-transform: scale(0.90);
		        transform: scale(0.90);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@media only screen and (min-width: 1024px) {
  .navbar,
  .mobile-checkout-stepper {
    display: none !important;
  }
}

/* Import Bootstrap and its default variables */

@media only screen and (max-width: 1024px) {

  .public .left-pane .wrapper,
  .public .left-pane .offer-wrapper {
    width: calc(100% - 2*20px);
    padding: 40px 20px 160px;
  }

  .right-pane {
    display: none;
  }

  .auth-modal-p {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    font-size: 0.90rem;
  }

  .celebration-image{
      width: 100%;
  }

  .description-share-modal {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }

  .page-top {
    display: flex;
    margin-top: 100px;
    width: 100%;
  }

  .page-info {
    display: flex;
    flex-direction: column;
  }

  .badges-grid {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }

  main.grid {
    width: 100%;
    margin: 0 !important;
  }

  .page-description {
    font-size: 12px !important;
  }

  .hidden {
    display: none !important;
  }

  .reward-creation-container{
    flex-direction: column;
  }

  /*PUBLIC REWARD*/

  #reward-preview .organization-mobile {
    display: inline-block;
  }

  #reward-preview .organization-desktop  {
    display: none;
  }

  #reward-preview .course-mobile,
  .course-mobile a {
    display: inline-block;
  }

  #reward-preview .course-desktop{
    display: none;
  }

  #reward-preview .title .exclamation-icon {
    top: 0;
  }

  #reward-preview .title button {
    padding: 0 0 0 8px;
    margin: 0;
  }

  #reward-preview .collection-container .mobile {
    display: block;
    top: 10px;
  }

  #reward-preview .collection-container .desktop {
    display: none;
  }

  #reward-preview .metadata-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .reward-preview{
    width: auto;
    height: auto;
    flex-direction: column;
    padding: 15px 20px 20px;
  }

  #reward-preview .badge-image {
    width: 100%;
    height: auto;
    -webkit-margin-before: 30px;
            margin-block-start: 30px;
  }

  #reward-preview .description-container {
    display: flex;
    margin: 30px 0 40px 0;
    grid-gap: 5px;
    gap: 5px;
    padding: 10px;
    flex-direction: column;
    white-space: pre-line;
  }

  /*OfferCard.js*/

  .offer-card,
  .order-card {
    flex-direction: column;
  }

  .order-card-details-container,
  .order-card-button-container {
    margin: 15px;
  }

  .offer-card-image {
    margin: 0;
  }

  .offer-card-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .claim-page-overlapping-image{
    width: 50px;
    margin-bottom: 10px;
  }

  .claim-page-overlapping-cta {
    font-size: small;
  }

}

