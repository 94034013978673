/**
* Public Pages Layouts (sidebar & centered)
* Claim, Preview, Login, Signup, Forgot Password, etc...
**/

#root, #root > div[data-rk], html, body {
    height: 100%;
    margin: 0;
}

.login .left-pane {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .left-pane .wrapper {
  grid-row-gap: 30px;
  row-gap: 30px
}

/* LAYOUT SPECIFIC */
.layout-sidebar {
  display: flex;
  height: 100%;
}

.layout-centered .left-pane {
  background-image: var(--supportImage);
  background-repeat: no-repeat;
  background-attachment: local;
  /* background-size: 50%; */
  background-position: center;
}

/* Left Pane */
.left-pane {
  text-align: left;

  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
  overflow-y: auto;
  -webkit-box-shadow: 0 20px 110px 0 rgb(0 12 44 / 8%);
  box-shadow: 0 20px 110px 0 rgb(0 12 44 / 8%);
  z-index: 2;
  background-color: var(--primaryBackgroundColor);
  justify-content: center;
}

.left-pane .wrapper{
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: var(--paddingPage);
  margin: 0 auto;
}

.left-pane .offer-wrapper{
  width: 1200px;
}

.left-pane .logo img{
  max-width: 100%;
}

.left-pane .header{
  margin-top: 60px;
}

.left-pane .header p{
  margin-top: 0px;
}

.left-pane .wrapper .content {
  color: var(--textColor);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.left-pane .wrapper .content form {
  max-width: 400px;
  margin-top: 64px;
}

.left-pane .wrapper .content form .MuiFormControlLabel-root {
  margin-top: 16px;
  margin-bottom: 8px;
}

/* Right Pane */
.right-pane {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 40%;
  flex: 1 0 40%;
  position: relative;
  z-index: 1;

  background-image: var(--supportImage);
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: bottom right;
}

/* ASSORTED */

.no-account {
  margin-top: 120px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

.no-account a,
.label-terms a,
.label-privacy a,
.no-account-modal a {
  color: var(--textColor);
  text-decoration: underline;
}

.footer-labels {
  letter-spacing: 0.2px;
  color: #68707b;
  font-size: 14px;
  font-weight: 300;
  margin-top: 30px;
}

.footer-labels p {
  display: inline-block;
  padding: 0 10px;
  margin: 0;
}

.footer-labels p:first-child {
  padding-left: 0px;
}

.footer-labels p:not(:last-child) {
  border-right: 1px solid;
}

.label-in-checkbox a {
  color: #000000;
  text-decoration: underline;
}

