#modal-image{
    z-Index: 0;
    object-fit: cover;
    max-width:60%;
    max-height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.customization-bar{
    display: flex;
    gap: 1%;
    align-items: center;
}

.item-selector{
    display: flex;
    align-items: center;
    gap: 0.5%;
} 

.dynamic-box{
    border: solid 2px transparent;
}
  
.dynamic-box:hover{
    cursor: move;
    border: solid 2px #e55e29;
    border-radius: 5px;
}

.left{
    text-align: left;
}