@media only screen and (max-width: 1024px) {

  .public .left-pane .wrapper,
  .public .left-pane .offer-wrapper {
    width: calc(100% - 2*20px);
    padding: 40px 20px 160px;
  }

  .right-pane {
    display: none;
  }

  .auth-modal-p {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    font-size: 0.90rem;
  }

  .celebration-image{
      width: 100%;
  }

  .description-share-modal {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }

  .page-top {
    display: flex;
    margin-top: 100px;
    width: 100%;
  }

  .page-info {
    display: flex;
    flex-direction: column;
  }

  .badges-grid {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }

  main.grid {
    width: 100%;
    margin: 0 !important;
  }

  .page-description {
    font-size: 12px !important;
  }

  .hidden {
    display: none !important;
  }

  .reward-creation-container{
    flex-direction: column;
  }

  /*PUBLIC REWARD*/

  #reward-preview .organization-mobile {
    display: inline-block;
  }

  #reward-preview .organization-desktop  {
    display: none;
  }

  #reward-preview .course-mobile,
  .course-mobile a {
    display: inline-block;
  }

  #reward-preview .course-desktop{
    display: none;
  }

  #reward-preview .title .exclamation-icon {
    top: 0;
  }

  #reward-preview .title button {
    padding: 0 0 0 8px;
    margin: 0;
  }

  #reward-preview .collection-container .mobile {
    display: block;
    top: 10px;
  }

  #reward-preview .collection-container .desktop {
    display: none;
  }

  #reward-preview .metadata-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .reward-preview{
    width: auto;
    height: auto;
    flex-direction: column;
    padding: 15px 20px 20px;
  }

  #reward-preview .badge-image {
    width: 100%;
    height: auto;
    margin-block-start: 30px;
  }

  #reward-preview .description-container {
    display: flex;
    margin: 30px 0 40px 0;
    gap: 5px;
    padding: 10px;
    flex-direction: column;
    white-space: pre-line;
  }

  /*OfferCard.js*/

  .offer-card,
  .order-card {
    flex-direction: column;
  }

  .order-card-details-container,
  .order-card-button-container {
    margin: 15px;
  }

  .offer-card-image {
    margin: 0;
  }

  .offer-card-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .claim-page-overlapping-image{
    width: 50px;
    margin-bottom: 10px;
  }

  .claim-page-overlapping-cta {
    font-size: small;
  }

}
