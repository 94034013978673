.left-pane .wrapper .content {
  position: relative;
}

#explosion {
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: white;
  filter: blur(60px);

  position: absolute;
  top: 130px;
  left: 0;

  animation: box-explosion 2.5s linear 0s 1 normal none;

}

#backdrop{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 120vh;

  animation: backdrop-pop 1.5s linear 2.5s 1 normal forwards;
}

div#backdrop .grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 60px;
}

.revealed-token {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.revealed-token img {
  width: 100%;
  transform:rotateX(90deg);

  animation: card-flip 1s linear 5s 1 normal forwards;
}

.revealed-token video {
  width: 100%;
}

.revealed-token .img-wrapper,
.revealed-token .video-wrapper {
  width: -webkit-fill-available;
}

.revealed-token .img-wrapper:hover {
  animation: card-shake 0.5s linear 0s 1 normal none;
}

.revealed-token .title {
  color: white;
  font-size: 2em;
  margin: 20px;
  text-align: center;
}

.revealed-token a.button {
  color: white;
  background-color: #e80233;
  padding: 10px 20px;
  display: inline-block;
}

.revealed-token .link {
  min-height: 40px;
}

.revealed-token .future {
  color: rgb(199, 199, 199);
  font-style: italic;
}

@media only screen and (max-width: 1024px) {
  div#backdrop .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: 100%;
  }

  .revealed-token:nth-child(1),
  .revealed-token:nth-child(3) {
    display: none;
  }
}


@keyframes box-explosion {
  0% {
    transform:scale(0);
    opacity: 0;
  }
  100% {
    transform:scale(1);
    opacity: 1;
  }
}

@keyframes backdrop-pop {
  0% {
    opacity:0;
    background-color: white;
  }
  45% {
    background-color:rgb(255, 92, 92);
    opacity:0.8;
  }
  55% {
    background-color:rgb(253, 186, 186);
    opacity:0.6;
  }
  100% {
    background-color:rgb(55, 52, 52);
    opacity:1;
  }
}

@keyframes card-flip {
  0% {
    transform:
    matrix3d(1,0,0.00,0,0.00,0,1.00,0.008,0,-1,0,0,0,0,0,1) scale(0.5);
  }
  100% {
    transform:rotateX(0deg) scale(1);
  }
}

@keyframes card-shake {
  0% {
    transform:rotate(0deg);
    transform-origin:50% 100%;
  }
  10% {
    transform:rotate(1deg);
  }
  20% {
    transform:rotate(-2deg);
  }
  30% {
    transform:rotate(2deg);
  }
  40% {
    transform:rotate(-2deg);
  }
  50% {
    transform:rotate(2deg);
  }
  60% {
    transform:rotate(-2deg);
  }
  70% {
    transform:rotate(2deg);
  }
  80% {
    transform:rotate(-1deg);
  }
  90% {
    transform:rotate(1deg);
  }
  100% {
    transform:rotate(0deg);
    transform-origin:50% 100%;
  }
}
