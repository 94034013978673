@import './fonts.css';

/* ALL THEMES */

.theme .share-container .MuiButton-outlined:not(.Mui-disabled, .MuiButton-outlinedWarning) {
  border-color: var(--textColor) !important;
  color: var(--textColor) !important;
}

/* THEME SLB */
.theme-slb {
  font-family: 'Mont', sans-serif;
  font-weight: 200;
}

.theme-slb .left-pane .wrapper {
  padding-top: 40px;
}

.theme-slb .logo {
  margin-left: -160px;
}

.theme-slb .logo img{
  width: 60px;
}

.theme-slb .left-pane {
  background-size: cover;
}

.theme-slb .header {
  font-size: 40px;
  font-weight: 200;
  text-align: center;
}

.theme-slb .header p {
  margin-bottom: 0;
}

.theme-slb #reward-preview .title {
  font-weight: 600;
}

.theme-slb .media-container {
  margin-top: 80px;
}

.theme-slb .metadata-preview {
  border: 1px solid var(--textColor);
}

.theme-slb .gift-checkbox .MuiTypography-root {
  font-weight: 200;
  font-family: 'Mont', sans-serif;
  font-size: var(--fontSize);
}

.theme-slb .description-container h2 {
  font-size: 40px;
  font-weight: 200;
}

.theme-slb .exclamation-icon svg {
  color: var(--textColor);
}

@media only screen and (max-width: 1024px) {
  .theme-slb .logo {
    margin-left: 0;
  }

  .theme-slb .header {
    font-size: 28px;
  }
}

/* THEME TVI */
@media only screen and (max-width: 1900px) {
  .theme-tvi .left-pane {
    background-size: 75%;
  }
}

@media only screen and (max-width: 1400px) {
  .theme-tvi .left-pane {
    background-size: 95%;
  }
}

@media only screen and (max-width: 1000px) {
  .theme-tvi .left-pane {
    background-image: none;
  }
}

/* THEME SAMSYS */
.theme-samsys .right-pane {
  background-position: 0 0;
  background-size: cover;
}

/* THEME FCT NOVA */
.theme-fct .right-pane {
  background-position: 0 0;
  background-size: cover;
}
