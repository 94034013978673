
@font-face {
  font-family: 'Mont';
  src: local('Mont-Light'), url(assets/fonts/Mont-Light.otf) format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'Mont';
  src: local('Mont-Regular'), url(assets/fonts/Mont-Regular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Mont';
  src: local('Mont-Bold'), url(assets/fonts/Mont-Bold.otf) format('opentype');
  font-weight: 600;
}

