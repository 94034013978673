/* Reward Preview */

#reward-preview .title{
  display: flex;
  justify-content: center;
  text-align: center; /* mobile */
  margin-top: 30px;
  color: var(--titleColor);
  font-size: var(--titleSize);
  line-height: 40px;
  font-weight: bold;
}

#reward-preview .badge-image {
  width: 100%;
  max-width: inherit;
}

#reward-preview .certificate-image {
  width: 550px;
  max-width: inherit;
  margin-block-start: 50px;
}

#reward-preview .student,
#reward-preview .creator {
  font-size: var(--titleSize);
  font-weight: bold;
  color: var(--textColor);
  margin-top: 15px;
}

#reward-preview .text {
  color: var(--textColor);
  font-size: var(--subtitleSize);
}

#reward-preview .onboarding {
  color: var(--textColor);
}

#reward-preview .media-container {
  width: 100%;
  text-align: center;
  position: relative;
}

#reward-preview .media-container > * {
  max-width: 100%;
}

#reward-preview .collection-container {
  text-align: center;
}

#reward-preview .collection-container .mobile{
  display: none;
}

#reward-preview .organization-desktop{
  margin-top: 10px;
  color: var(--textColor);
  font-size: 20px;
  font-weight: bold;
}
#reward-preview .organization-mobile{
  display: none;
}

#reward-preview .course-desktop{
  margin-top: 10px;
  font-size: 18px;
  line-height: auto;
  color: var(--textColor);
}

#reward-preview .course-desktop a {
  color: var(--textColor);
  text-decoration-color: var(--textColor);
  padding-left:  10px;
}

#reward-preview .share-container,
#reward-preview .offers-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#reward-preview .share-container button img {
  width: 24px;
}

#reward-preview .note {
  color: #68707b;
  text-align: center;
  font-size: 14px;
}

#reward-preview .description-container {
  margin-top: 80px;
  white-space: pre-line;
  font-size: var(--descriptionSize);
  line-height: 1.4em;
  color: var(--textColor);
}

#reward-preview .description-container h3 {
  margin-bottom: -15px;
}

#reward-preview .description-container p {
  margin-bottom: 0px;
}

.description-container a {
  color: var(--textColor);
  text-decoration: underline;
}

#reward-preview .metadata-container {
  margin-top: 80px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px 14px;
}

#reward-preview .metadata-preview {
  border-radius: 10px;
  background-color: var(--metadataBoxColor);
  color: var(--metadataTextColor);
  padding: 14px;
}

#reward-preview .metadata-preview h4,
#reward-preview .metadata-preview p {
  margin: 0;
}

#reward-preview .metadata-preview h4{
  margin-bottom: 14px;
}


#reward-preview .block h4 {
  margin:  0;
}

#reward-preview .block p {
  color: #68707b;
  margin: 10px 0 0;
}


#reward-preview .skeletonBox {
  display: flex;
  justify-content: center;
}

/* CLAIM ACTIONS */
.actions-container {
  margin-top: 55px;
}

.grid.buy-actions-container {
  width: 100%;
  grid-template-columns: 1fr 120px 1fr;
  row-gap: 20px;
  column-gap: 40px;
  font-size: 14px;
}

.buy-actions-amount {
  border-top: 1px solid var(--textColor);
  border-bottom: 1px solid var(--textColor);
  display: grid;
  grid-template-columns: 26% 40% 26%;
  gap: 0px 0px;
  text-align: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.buy-actions-amount > .amount-selector {
  border-left: 1px solid var(--textColor);
  border-right: 1px solid var(--textColor);
  background-color: rgba(255, 255, 255, 0.1);
}

.buy-actions-amount > .amount-selector .MuiAmountIconButton:not(.Mui-disabled) {
  color: var(--textColor) !important;
}

.buy-actions-container .price {
  text-align: left !important;
  font-size: 40px;
  font-weight: 400;
}

.buy-button.MuiButton-contained {
  box-shadow: none;
  height: 100%;
  border-radius: 0;
}

.gift-checkbox {
  margin-top: -5px;
}

.gift-checkbox span:not(.Mui-checked) svg.MuiSvgIcon-root {
  color: rgba(var(--textColor), 0.6);
}

.gift-checkbox .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}

/* USER NOTICE */

.user-notice {
  text-align: center;
  margin-top: 80px;
  padding-bottom: 0px;
  font-size: var(--descriptionSize);
}

/* SHARE MODAL */

.share-label {
  font-family: var(--fontFamily);
}

.share-modal-p {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.share-modal-label {
  text-align: center;
  margin: 0;
}

.description-share-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.button-container{
  display: flex;
  position: relative;
  justify-content: center;
  padding: 15px 0px 30px 0px;
}

.claim-settings-container{
  color: var(--textColor);
  padding-top: 10px;
}


@media only screen and (max-width: 1024px) {
  .grid.buy-actions-container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'price amount'
        'supply total'
        'button button'
        'gift gift';
  }

  .grid.buy-actions-container .price {
    grid-area: price;
  }

  .grid.buy-actions-container .amount {
    grid-area: amount;
    justify-self: right;
  }

  .buy-actions-amount {
    width: 140px;
    grid-template-columns: 30% 40% 30%;
  }

  .grid.buy-actions-container .button {
    grid-area: button;
    text-align: center;
    margin-top: 50px;
  }

  .grid.buy-actions-container .button .buy-button {
    max-width: 240px;
    padding: 14px 28px;
  }

  .grid.buy-actions-container .supply {
    grid-area: supply;
  }

  .grid.buy-actions-container .total {
    grid-area: total;
    width: 140px;
    justify-self: right;
  }

  .grid.buy-actions-container .gift-checkbox {
    grid-area: gift;
    max-width: 240px;
    margin: 0 auto;
    width: 100%;
  }

}
